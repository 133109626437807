<template>
  <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Обновить направление"
      hide-footer
      size="lg"
  >
    <Form ref="form" btn-title="Изменить" btn-variant="primary" @onSave="updateDirection"/>
  </b-modal>
</template>

<script>
import Form from './Form'

export default {
  name: "Update",
  methods: {
    show(form) {
      console.log(form)
      this.$refs.modal.show()
      setTimeout(() => {
        this.$refs.form.fillForm(form)
      }, 0);
    },
    updateDirection(form) {
      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('PopularDirections/update', form)
          .then(() => {
            this.$refs.modal.hide()
            this.$refs.form.resetForm()
            this.$bvToast.toast('Направление было изменено.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
          })
    }
  },
  components: {
    Form
  }
}
</script>

<style scoped>

</style>