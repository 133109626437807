<template>
  <div>
    <b-col cols="12">
      <h2>Популярные направления</h2>
    </b-col>
    <b-col cols="12" class="mb-4 d-flex justify-content-end">
      <b-button variant="primary" @click="$refs.createModal.show()">Добавить направление</b-button>
    </b-col>
    <create ref="createModal"/>
    <update ref="updateModal"/>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4">
          <b-table :items="data" :fields="fields" :small="true">
            <template #cell(actions)="data">
              <div class="text-left">
                <b-icon class="action-icon" icon="pencil-fill" variant="primary" font-scale="1.2" @click="update(data.item)" title="Редактировать"></b-icon>
                <b-icon class="action-icon" icon="trash-fill" variant="danger" font-scale="1.2" @click="remove(data.item.id)" title="Удалить"></b-icon>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from "vuex"
import Create from './PopularDirections/Create'
import Update from './PopularDirections/Update'
import moment from "moment";

export default {
  name: "PopularDirections",
  data() {
    return {
      errors: {},
      fields: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'title',
          label: 'Заголовок'
        },
        {
          key: 'subtitle',
          label: 'Краткое описание'
        },
        {
          key: 'price',
          label: 'Цена'
        },
        {
          key: 'date',
          label: 'Дата',
          formatter:  value => moment(value).format('DD.MM.YYYY')
        },
        {
          key: 'isActive',
          label: 'Активность',
          formatter: (value) => value ? 'Активна' : 'Неактивна'
        },
        {
          key: 'isShowInMain',
          label: 'Показывать на главной',
          formatter: (value) => value ? 'Да' : 'Нет'
        },
        {
          key: 'actions',
          label: 'Действия'
        }
      ]
    }
  },
  mounted() {
    this.$store.dispatch('PopularDirections/load', { page: 1 })
  },
  computed: {
    ...mapState({
      loading: state => state.PopularDirections.loading,
      data: state => state.PopularDirections.items,
      page: state => state.PopularDirections.page,
      maxPages: state => state.PopularDirections.maxPages,
    })
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    load(event, page) {
      event.preventDefault()
      this.$store.dispatch('PopularDirections/load', { page })
    },
    remove(id) {
      if (!id)
        return

      if (!confirm('Вы действительно хотите удалить направление?'))
        return

      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('PopularDirections/remove', id)
          .then(() => {
            this.$bvToast.toast('Направление было успешно удалено.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
          })
    },
    update(form) {
      this.$refs.updateModal.show(form)
    }
  },
  components: {
    Create,
    Update
  }
}
</script>

<style scoped>
.action-icon {
  margin: 4px;
  cursor: pointer;
}
</style>