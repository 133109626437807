<template>
  <b-form>
    <b-row>
      <b-col md="12">
        <b-form-group label="Заголовок">
          <b-form-input v-model="form.title" placeholder="Заголовок" :state="formErrors.title"/>
        </b-form-group>
        <b-form-group label="Краткое описание">
          <b-form-textarea v-model="form.subtitle" placeholder="Краткое описание" :state="formErrors.subtitle"></b-form-textarea>
        </b-form-group>
        <b-form-group>
          <img v-if="form.hasImage" class="file__img"
               :src="form.img"
               alt="#" loading="lazy" decoding="async"
          />
          <b-form-file
              v-model="form.img"
              placeholder="Выберите изображение"
              drop-placeholder="Положите файл здесь..."
              @change="form.hasImage = false"
          />
        </b-form-group>
        <b-form-group label="Цена от">
          <b-form-input v-model="form.price" placeholder="Цена от" :state="formErrors.price" number/>
        </b-form-group>
        <b-form-group label="Начальный пункт">
          <b-form-select v-model="form.fromId" :state="formErrors.fromId">
            <option :value="null">Выберите пункт отправления</option>
            <option v-for="(point, index) in points" :value="point.id" :key="index">{{ point.name }}</option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Конечный пункт">
          <b-form-select v-model="form.toId" :state="formErrors.toId">
            <option :value="null">Выберите конечный пункт</option>
            <option v-for="(point, index) in points" :value="point.id" :key="index">{{ point.name }}</option>
          </b-form-select>
        </b-form-group>
        <b-form-checkbox v-model="form.isActive">
          Активность
        </b-form-checkbox>
        <b-form-checkbox v-model="form.isShowInMain">
          Показывать на главной
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import validate from "@/utils/formValidator"
import POPULAR_DIRECTION from "@/forms/POPULAR_DIRECTION"
import DeparturesApi from "@/api/departures.api";

const POPULAR_DIRECTIONS_INIT_FORM = () => ({
  title: '',
  subtitle: '',
  fromId: null,
  toId: null,
  img: null,
  price: '',
  hasImage: false,
  isActive: true,
  isShowInMain: false
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => POPULAR_DIRECTIONS_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      points: [],
      form: this.init,
      errors: {}
    }
  },
  mounted() {
    DeparturesApi.getAllPoints().then(response => {
      this.points = response.data
    });
  },
  computed: {
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    }
  },
  methods: {
    fillForm(form) {
        Object.assign(this.form, form)
    },
    resetForm() {
      Object.assign(this.form, POPULAR_DIRECTIONS_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, POPULAR_DIRECTION)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return

      this.$emit('onSave', this.form);
    }
  }
}
</script>

<style scoped>
  .file__img {
    width: 300px;
    height: auto;
    margin-bottom: 20px;
  }
</style>